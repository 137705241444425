import { Row, Button, Col } from 'react-bootstrap'
import { labels } from './labels'

export default function ParrafoBoton({ idioma }) {
  return (
    <Row className="fondo-contenedor">
      <p>{labels.parrafo1[idioma]}</p>
      <p>{labels.parrafo2[idioma]}</p>

      <Col>
        <Button
          href={labels.link[idioma]}
          target="_blank"
          rel="noopener noreferrer"
          variant="warning"
          size="lg"
          className="mt-4"
        >
          {labels.boton1[idioma]}
        </Button>
      </Col>
    </Row>
  )
}
