import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { terms, labels } from '../../utils/terminosYcondiciones'
import { Button } from 'react-bootstrap'

function ModalTC({ show, setShow, titulo, idioma }) {
  const handleAcceptTerms = () => {
    window.localStorage.setItem('accept-terms', true)

    setShow(false)
  }

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        fullscreen={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="terms">{terms[idioma]}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAcceptTerms}>
            {labels.acceptButton[idioma]}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalTC
