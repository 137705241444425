import { Container, Row, Col } from 'react-bootstrap'
import Imagen from '../Imagen'
import imgAutor from '../../assets/img/autor.jpg'
import { labels } from './labels'

function ImagenParrafo({ idioma }) {
  return (
    <Container fluid className="seccion-3" id="autor">
      <Row className="flex-column-reverse flex-lg-row">
        <Col lg={6} className="img-autor-container ">
          <Imagen url={imgAutor} className="img-autor" />
        </Col>
        <Col lg={6} className="mt-lg-5">
          <h2 className="nombre-autor titulo-libro"> Enrique Suárez</h2>
          <p className="mt-3">{labels.parrafo1[idioma]}</p>
          <p>{labels.parrafo2[idioma]}</p>
          <p>{labels.parrafo3[idioma]}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mt-3 mt-lg-0">{labels.parrafo4[idioma]}</p>
        </Col>
      </Row>
    </Container>
  )
}

export default ImagenParrafo
