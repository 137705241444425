import { Image, NavLink } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { labels } from './labels'

import flagEsp from '../../assets/img/esp.png'
import flagUs from '../../assets/img/us.png'

const idiomas = [
  {
    title: 'Click para cambiar el idioma a Ingles',
    label: 'idioma: Español',
    flag: flagEsp,
  },
  {
    title: 'Click to change language to Spanish',
    label: 'language English',
    flag: flagUs,
  },
]

function NavbarPage({ setIdioma, idioma }) {
  function cambiarIdioma() {
    const id = idioma === 0 ? 1 : 0
    setIdioma(id)
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-page fixed-top">
      <Container>
        <Navbar.Brand href="#home">{labels.titulo1[idioma]}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="#home">{labels.titulo2[idioma]}</Nav.Link>
            <Nav.Link eventKey={2} href="#autor">
              Enrique Suárez
            </Nav.Link>
            <Nav.Link href="#libro">{labels.titulo3[idioma]}</Nav.Link>
            <NavLink href="#comprar">{labels.titulo4[idioma]}</NavLink>
            <NavLink onClick={cambiarIdioma} title={idiomas[idioma].title}>
              <b>
                {' '}
                {idiomas[idioma].label}{' '}
                <Image src={idiomas[idioma].flag} width={20} />{' '}
              </b>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarPage
