export const labels = {
  parrafo1: [
    'Si algún libro nos mueve a la reflexión y a cuestionarnos muchas de las ideas que hemos permitido se establezcan en nuestro cerebro y que rigen nuestra existencia, es este que está usted empezando a disfrutar.',
    'If any book moves us to reflection and to question many of the ideas we have allowed to become established in our brains that govern our existence, it is this one that you are beginning to enjoy.',
  ],
  parrafo2: [
    'Este trabajo es el resultado de haber meditado, por mucho tiempo, sobre la esencia de la vida y sus propósitos. Algunas de las conclusiones a las que ha llegado el autor retan al dogmatismo prevaleciente, es una respuesta a muchas de las inquietudes del ser humano y representa una fuente de excelentes mensajes para los lectores.',
    'This work is the result of having meditated, for a long time, on the essence of life and its purposes. Some of the conclusions reached by the author challenge the prevailing dogmatism. It answers many of the concerns of the human being and represents a source of excellent messages for the readers.',
  ],
  boton1: ['Comprar libro', 'Buy book'],
  link: [
    'https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-populista-Spanish/dp/B0B5KQ49WZ/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=',
    'https://www.amazon.com.mx/Dogmatic-Tyranny-Populist-English-ebook/dp/B0BJRWRT7R/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=15GL7MUS1DF14&keywords=Dogmatic+tyranny&qid=1680906912&s=books&sprefix=dogmatic+tyranny%2Cstripbooks%2C118&sr=1-1',
  ],
}
