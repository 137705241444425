import Carousel from 'react-bootstrap/Carousel'

import imgLibros from '../../assets/img/libros.jpg'
import { frases } from './labels'

function FrasesCarrousel({ idioma }) {
  return (
    <Carousel className="frases-carousel">
      <Carousel.Item>
        <img className="d-block w-100" src={imgLibros} alt="libros" />
      </Carousel.Item>

      {frases.map((frase) => (
        <Carousel.Item>
          <Carousel.Caption className="frases">
            <h1>{frase.frase[idioma]}</h1>
            <p className="autor-frase">
              - Carl Sagan {idioma ? ' (adapted)' : '(adaptado)'}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default FrasesCarrousel
