const TermsEsp = (
  <>
    <h3>PROEMIO</h3>
    <p>
      Al ingresar y utilizar este portal de Internet, cuyo nombre de dominio es
      https://tiraniapopulista.com, propiedad de Aquiles Enrique Fernando Suárez
      Montes, que en lo sucesivo se denominará “Tiranía Populista”, el usuario
      está aceptando los Términos y condiciones de uso contenidos en este
      convenio y declara expresamente su aceptación utilizando para tal efecto
      medios electrónicos, en términos de lo dispuesto por el artículo 1803 del
      Código Civil Federal.
    </p>
    <p>
      En caso de no aceptar en forma absoluta y completa los términos y
      condiciones de este convenio, el usuario deberá abstenerse de acceder,
      utilizar y observar el sitio web https://tiraniapopulista.com
    </p>
    <p>
      Y en caso de que el usuario acceda, utilice y observe el sitio web
      https://tiraniapopulista.com se considerará como una absoluta y expresa
      aceptación de los Términos y condiciones de uso aquí estipulados.
    </p>
    <p>
      La sola utilización de dicha página de Internet le otorga al público en
      general la condición de usuario (en adelante referido como el «usuario» o
      los «usuarios») e implica la aceptación, plena e incondicional, de todas y
      cada una de las condiciones generales y particulares incluidas en estos
      Términos y condiciones de uso publicados por https://tiraniapopulista.com
      en el momento mismo en que el usuario acceda al sitio web.
    </p>
    <p>
      Cualquier modificación a los presentes Términos y condiciones de uso será
      realizada cuando el titular de la misma, en este caso “Aquiles Enrique
      Fernando Suárez Montes”, lo considere apropiado, siendo exclusiva
      responsabilidad del usuario asegurarse de tomar conocimiento de tales
      modificaciones.
    </p>
    <h3>I. Condiciones generales</h3>
    <h3>1. Objeto</h3>
    <p>
      Los presentes términos y condiciones generales de uso (en adelante las
      “Condiciones”) tienen por objeto regular el acceso y uso que hagan
      aquellas personas físicas o morales identificadas como usuarios del sitio
      web https://tiraniapopulista.com o cualquiera de sus extensiones (en
      adelante el “Sitio Web”) el cual tiene el uso exclusivo el autor AQUILES
      ENRIQUE FERNANDO SUÁREZ MONTES.
    </p>
    <h3>2. Definiciones</h3>
    <p>
      Para efectos de claridad de las presentes Condiciones se establecen las
      definiciones respecto de las palabras que se enlistan a continuación y
      cómo deberán de entenderse, teniendo el mismo significado tanto si se usa
      en singular como en plural:
    </p>
    <p>
      • Tiranía Populista: Significa TIRANÍA DOGMÁTICA Y TIRANÍA POPULISTA, Y/O
      Aquiles Enrique Fernando Suárez Montes, en su carácter de propietaria del
      Sitio Web.
    </p>
    <p>
      • Condiciones: Significa los presentes términos y condiciones generales de
      uso.
    </p>
    <p>
      • Cuenta: Significa el medio de identificación del Usuario que podrán
      crear en TIRANÍA POPULISTA aquellas personas con capacidad suficiente,
      incluyendo aquellas en representación de personas morales debidamente
      constituidas y representadas, que tengan la intención de hacer uso de las
      herramientas que el Sitio web ofrece bajo estas Condiciones.
    </p>
    <p>
      • Enlaces: Significa cualesquier otro dominio o hipervínculo a los que se
      puede acceder de cualquier manera mediante el Sitio Web.
    </p>
    <p>
      • Funcionalidades: Significan todas las herramientas, funcionalidades y
      asistencia que se describen en el punto correspondiente de las presentes
      Condiciones.
    </p>
    <p>• México: Significa Estados Unidos Mexicanos.</p>
    <p>
      • Sitio Web: tiraniapopilista.com. de la cual es titular Aquiles Enrique
      Fernando Suárez Montes.
    </p>
    <p>
      • Tercero: Significa cualquier persona que no sea parte de esta página
      incluyendo sus directivos, trabajadores, representantes o dependiente de
      alguna forma ni el Usuario
    </p>
    <p>
      • Usuario: Significa toda persona, ya sea física o moral, que esté
      interesada en hacer uso de las Funcionalidades y de los Recursos Digitales
      contenidas en el Sitio Web, ya sea colaborando al prestar asesoría legal o
      al hacer uso de las Funcionalidades y de los Recursos Digitales.
    </p>
    <h3>3. Aceptación</h3>
    <p>
      La sola utilización del Sitio Web representa la aceptación, plena e
      incondicional, de los Usuarios de todas y cada una de las presentes
      Condiciones desde el momento mismo en que el Usuario acceda, y haga uso
      del Sitio Web.
    </p>
    <h3>4. Licitud y Propiedad Intelectual</h3>
    <p>
      El Sitio Web será utilizado únicamente para fines lícitos, de conformidad
      y en cumplimiento con la legislación vigente y aplicable en México, misma
      que puede ser modificada de tiempo en tiempo. El contenido, información
      y/o material que Aquiles Enrique Fernando Suárez Montes pone al alcance de
      los Usuarios a través de su Sitio Web contiene elementos amparados por
      normas de propiedad intelectual, incluyendo protección del software,
      fotografías, logos, diseños, imágenes gráficas, música y sonido, sin que
      esta descripción implique limitación de naturaleza alguna.
    </p>
    <p>
      Cualquier trasgresión a estas Condiciones por parte de un Usuario
      (incluyendo, pero no limitándose a la comisión de actos que, a criterio
      exclusivo de Aquiles Enrique Fernando Suárez Montes, constituyan conductas
      abusivas, inapropiadas o ilícitas) generarán el derecho, en favor de
      Aquiles Enrique Fernando Suárez Montes, en cualquier momento y sin
      necesidad de notificación previa de ningún tipo, para suspender o terminar
      el uso de las Funcionalidades, de los Recursos Digitales y/o de retirarle
      o negarle el acceso al Sitio Web y/o a su Cuenta a cualquier Usuario.
    </p>
    <h3>5. Prohibiciones</h3>
    <p>
      Se encuentra terminantemente prohibido cualquier tipo de copia,
      distribución, transmisión, retransmisión, publicación, impresión, difusión
      y/o explotación comercial del material y/o contenido puesto a disposición
      de los Usuarios y el público en general a través del Sitio Web y/o la
      Cuenta propia o de Terceros, sin el previo consentimiento expreso y por
      escrito de Aquiles Enrique Fernando Suárez Montes o, en su caso, del
      titular de los derechos de propiedad correspondientes.
    </p>
    <p>
      El incumplimiento de lo aquí mencionado sujetará al infractor a todos los
      reclamos civiles y sanciones administrativas y/o penales que pudieran
      corresponder. Asimismo, se encuentra prohibida la publicación y/o
      distribución de cualquier material que resulte obsceno, inapropiado,
      difamatorio o que pudiera resultar violatorio de los derechos de cualquier
      Tercero o resulte contrario a las políticas generales del Sitio Web y/o al
      ordenamiento jurídico vigente.
    </p>
    <h3>6. Capacidad</h3>
    <p>
      En caso de personas morales que hagan uso del Sitio Web, estas deberán de
      estar debidamente constituidas en términos de la legislación de personas
      morales y representadas mediante su representante legal, según lo
      dispuesto por estas condiciones y cualquier otra legislación vigente
      aplicable en, incluyendo los daños que se le pudieran causar a Terceros
      durante la representación haciendo uso del Sitio Web.
    </p>
    <h3>II. Usuario</h3>
    <h3>1. Visitantes.</h3>
    <p>
      Los visitantes del Sitio Web podrán visualizar las Funcionalidades; así
      como navegar a través de éste libremente sin la necesidad de contar con
      una Cuenta, aunque no puedan hacer uso total de dichas Funcionalidades.
    </p>
    <h3>III. Obligaciones de las partes.</h3>
    <h3>1. Obligaciones del Usuario.</h3>
    <p>
      Serán obligaciones del Usuario, sin detrimento de las que surjan en los
      demás apartados de las presentes Condiciones, las siguientes:
    </p>
    <p>
      1. Realizar todos aquellos actos oportunamente según se requieran por
      parte del Usuario para el correcto uso de las Funcionalidades.
    </p>
    <p>
      2. No utilizar los contenidos y productos del Sitio Web con la finalidad
      de faltar a la ley, la moral, las buenas costumbres, regulación de
      propiedad intelectual, la dignidad, la vida privada, la imagen, la
      intimidad personal y familiar.
    </p>
    <p>
      3. No utilizar las Funcionalidades, los Recursos Digitales, contenidos y
      productos proporcionados por AQUILES ENRIQUE FERNANDO SUÁREZ MONTES para
      transmitir o divulgar material ilegal, difamatorio, abusivo, amenazante,
      obsceno, perjudicial, vulgar, injurioso o de cualquier otra forma
      censurable.
    </p>
    <p>
      4. Cumplir con todas las leyes aplicables con relación a la transmisión de
      datos desde México.
    </p>
    <p>
      5. No obtener o intentar obtener acceso a otros sistemas de redes o de
      computadoras conectados a los contenidos y productos del Sitio Web.
    </p>
    <p>
      6. No interferir o interrumpir las Funcionalidades o los Recursos
      Digitales de los servidores, redes conectadas a las Funcionalidades, los
      Recursos Digitales, contenidos y productos abastecidos por medio del Sitio
      Web o de los Enlaces.
    </p>
    <p>
      7. No enviar o transmitir por medio del Sitio Web cualquier contenido o
      información que contenga cualquier propaganda religiosa o política.
    </p>
    <p>
      8. Evitar realizar cualquier conducta que pudiera restringir o inhibir el
      uso del Sitio Web o de los Enlaces por otros Usuarios o Terceros.
    </p>
    <p>
      El Usuario está de acuerdo en sacar en paz y a salvo a Aquiles Enrique
      Fernando Suárez Montes, sus empresas afiliadas, sus proveedores, terceros
      que colaboren con Aquiles Enrique Fernando Suárez Montes y proveedores por
      cualesquiera reclamaciones, procesos, acciones, así como cualesquiera
      otras responsabilidades (inclusive honorarios jurídicos) derivados de su
      uso o de la utilización indebida del Sitio Web, de la violación de estas
      Condiciones, la violación de los derechos de cualquier otra persona o
      entidad, o cualquier violación de las declaraciones, garantías y acuerdos
      hechos aquí por el Usuario; así como indemnizarlos por los daños y
      perjuicios que correspondieren.
    </p>
    <h3>
      2. Compromisos de TIRANÍA DOGMATICA / Aquiles Enrique Fernando Suárez
      Montes.
    </h3>
    <p>
      Serán compromisos de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, además, sin
      detrimento de los que surjan en los demás apartados de las presentes
      Condiciones, los siguientes:
    </p>
    <p>
      1. Procurar el correcto funcionamiento de las Funcionalidades y de los
      Recursos Digitales a los Usuarios de conformidad con las Condiciones.
    </p>
    <p>
      2. Contar con todas las licencias y autorizaciones necesarias para la
      operación del Sitio Web, de las Funcionalidades y de los Recursos
      Digitales.
    </p>
    <h3>
      3. Continuidad en el Sitio Web, las Funcionalidades y los Recursos
      Digitales.
    </h3>
    <p>
      El Usuario reconoce que podrían presentarse (i) interrupciones en el uso
      de las Funcionalidades y en los Recursos Digitales u (i) otros hechos
      ocurridos por causas no imputables a AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES en donde se viera interrumpido, suspendido o detenido el uso y/o
      desarrollo de las Funcionalidades y de los Recursos Digitales. Bajo
      ninguno de estos casos, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES podrá ser
      responsabilizado de cualesquiera datos perdidos y/o transacciones no
      exitosas durante la transmisión de información por medio de internet.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no será responsable por
      interrupciones o suspensiones de las Funcionalidades o de los Recursos
      Digitales y del acceso a internet ocasionadas por la falla en el sistema
      de telecomunicaciones, en el suministro de energía eléctrica, casos
      fortuitos o de fuerza mayor o una acción de Terceros que puedan
      inhabilitar los equipos que suministran el acceso a internet y por lo
      tanto al Sitio Web.
    </p>
    <p>
      El Usuario reconoce que en el desarrollo de las Funcionalidades y de los
      Recursos Digitales, hay actos a realizarse por parte de Terceros que
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no controla, por lo que la
      realización de dichas actividades, el Usuario libera de toda
      responsabilidad a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, en el entendido
      que, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES procurará buscar que el
      Tercero referido realice sus actividades encomendadas para el correcto
      desarrollo de las Funcionalidades y de los Recursos Digitales.
    </p>
    <p>
      Por lo anterior, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no se
      responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados
      por fallas no imputables a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES en las
      Funcionalidades y los Recursos Digitales, en el servidor o en Internet.
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES tampoco será responsable por
      cualquier Malware que pudiera infectar o haber infectado el equipo del
      Usuario como consecuencia del acceso, uso o examen del Sitio Web o a raíz
      de cualquier transferencia de datos, archivos, imágenes, textos, o audios
      contenidos en el mismo entre Usuarios. Los Usuarios no podrán imputarle
      responsabilidad alguna ni exigir pago de daños o perjuicios, en virtud de
      dificultades técnicas o fallas no imputables a AQUILES ENRIQUE FERNANDO
      SUÁREZ MONTES en las Funcionalidades, los Recursos Digitales o en
      Internet.
    </p>
    <p>
      El Sitio Web puede, eventualmente, no estar disponible debido a
      dificultades técnicas o fallas de Internet, o por cualquier otra
      circunstancia ajena a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES; en tales
      casos, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES procurará restablecerlo con
      la mayor celeridad posible sin que por ello pueda imputársele algún tipo
      de responsabilidad. AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no será
      responsable por ningún error u omisión contenidos en el Sitio Web.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES podrá, en cualquier momento y a su
      exclusivo criterio, descontinuar, suspender, terminar o alterar la forma
      de acceso al Sitio Web con relación a cualquier contenido, periodo de
      disponibilidad y equipamiento necesarios para el acceso y/o utilización
      del Sitio Web. AQUILES ENRIQUE FERNANDO SUÁREZ MONTES se reserva el
      derecho de descontinuar la diseminación de cualquier información, alterar
      o eliminar métodos de transmisión, alterar la velocidad de transmisión de
      datos o cualesquiera otras características de señal sin necesidad de
      contar con el consentimiento previo del Usuario.
    </p>
    <h3>4. Negación de garantías</h3>
    <p>
      El usuario está de acuerdo que la utilización del sitio web
      https://tiraniapopulista.com se realiza bajo su propio riesgo y que los
      servicios y productos que ahí se prestan y ofrecen se prevén sobre una
      base «tal cual» y «según sean disponibles». Tiranía Populista” no
      garantiza que la página señalada satisfaga los requerimientos del usuario
      o que los servicios que en ella se ofrecen no sufran interrupciones, sean
      seguros o estén exentos de errores.
    </p>
    <p>
      Tiranía Populista” no garantiza o avala en ninguna forma la veracidad,
      precisión, legalidad, moralidad o ninguna otra característica del
      contenido del material que se publique en el sitio web
      https://tiraniapopulista.com
    </p>
    <p>
      Tiranía Populista” se libera de cualquier responsabilidad y condiciones,
      tanto expresas como implícitas, en relación con los servicios e
      información contenida o disponible en o a través de esta página web;
      incluyendo, sin limitación alguna:
    </p>
    <p>
      • a) La disponibilidad de uso del sitio web https://tiraniapopulista.com
    </p>
    <p>
      • b) La ausencia de virus, errores, desactivadores o cualquier otro
      material contaminante o con funciones destructivas en la información o
      programas disponibles en o a través de esta página o en general cualquier
      falla en dicho sitio.
    </p>
    <p>
      • c) No obstante lo anterior, Tiranía Populista o sus proveedores podrán
      actualizar el contenido de la página constantemente, por lo que se
      solicita al usuario tomar en cuenta que algunas informaciones publicitadas
      o contenidas en o a través de este sitio web pueden haber quedado
      obsoletas y/o contener imprecisiones o errores tipográficos u
      ortográficos.
    </p>
    <h3>5. Limitaciones a la responsabilidad</h3>
    <p>
      Hasta el máximo permitido por las leyes aplicables, Tiranía Populista no
      será responsable, en ningún caso, por daños directos, especiales,
      incidentales, indirectos, o consecuenciales que en cualquier forma se
      deriven o se relacionen con:
    </p>
    <p>
      • a) El uso o ejecución del sitio web https://tiraniapopulista.com, con el
      retraso o la falta de disponibilidad de uso de “Amazon”.
    </p>
    <p>
      • b) La proveeduría o falta de la misma de servicios de cualquier
      información o gráficos contenidos o publicados en o a través del sitio
      señalado.
    </p>
    <p>• c) La actualización o falta de actualización de la información.</p>
    <p>
      • d) La alteración o modificación, total o parcial, de la información
      después de haber sido incluida en dicho sitio.
    </p>
    <p>
      • e) Cualquier otro aspecto o característica de la información contenida o
      publicada en la página web o a través de las ligas que eventualmente se
      incluyan en este sitio.
    </p>
    <p>
      • f) La proveeduría o falta de proveeduría que los demás servicios, todos
      los supuestos anteriores serán vigentes, aún en los casos en que se le
      hubiere notificado o avisado a Amazon” acerca de la posibilidad de que se
      ocasionarán dichos daños.
    </p>
    <h3>IV. Propiedad y Derechos del Contenido del Sitio Web.</h3>
    <h3>1. Protección a los derechos de autor.</h3>
    <p>
      A los Usuarios se les advierte que los derechos sobre el contenido
      publicado por AQUILES ENRIQUE FERNANDO SUÁREZ MONTES en el Sitio Web
      pueden estar protegidos por la legislación vigente mexicana relativa a los
      derechos de autor, propiedad intelectual e industrial. Todo el contenido
      disponible en el Sitio Web, incluyendo los Enlaces, son propiedad
      exclusiva de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES o el autor del
      material publicado. AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no autoriza a
      los Usuarios y a terceros distribuir y/o transmitir el material que se
      encuentre en el Sitio Web cuya autoría sea de AQUILES ENRIQUE FERNANDO
      SUÁREZ MONTES. Queda prohibido cualquier tipo de modificación de
      información o de los Enlaces sin expresa autorización de AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES o del autor titular de tales derechos, previa
      notificación por escrito del propietario, así como la atribución indebida,
      ya sea parcial o total de la información o contenido sin autorización
      expresa de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES o del titular de esos
      derechos.
    </p>
    <p>
      Cualquier violación a lo dispuesto en este numeral constituirá una
      infracción a los derechos de autor, propiedad intelectual y sujetará al
      Usuario a las sanciones administrativas, civiles y penales que resulten
      aplicables.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES se reserva todos los derechos no
      expresamente otorgados bajo el presente documento. El Usuario declara y
      reconoce que la descarga de cualquier contenido del Sitio Web no le
      confiere la propiedad sobre cualesquiera marcas exhibidas en la misma.
    </p>
    <p>
      El Usuario no deberá subir (upload), publicar o de cualquier otra forma
      disponer en el Sitio Web y/o su Cuenta de cualquier material protegido por
      derechos de autor, registro de marcas o cualquier otro derecho de
      propiedad intelectual sin previa y expresa autorización del titular o
      licenciatario del mencionado derecho.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no tiene el deber o la
      responsabilidad de proporcionar al Usuario cualesquiera indicaciones que
      auxilien a la identificación del contenido como protegido por derechos de
      propiedad intelectual. El Usuario será el único responsable de
      cualesquiera daños a Terceros que resultaren de la violación de derechos
      de propiedad intelectual, en virtud de la utilización del mencionado
      contenido.
    </p>
    <h3>V. Responsabilidad.</h3>
    <h3>1. Límites de Responsabilidad.</h3>
    <p>
      En virtud de que AQUILES ENRIQUE FERNANDO SUÁREZ MONTES solo proporciona
      las Funcionalidades y los Recursos Digitales descritas en las presentes
      Condiciones de carácter oneroso, no se hace responsable de los productos
      y/o servicios ofrecidos por Terceros, incluyendo aquellos realizados por
      Terceros involucrados en el desarrollo de las Funcionalidades o de los
      Recursos Digitales, por lo que ningún Usuario o Tercero podrá hacer
      reclamación alguna a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES sobre algún
      vicio, daño y/o perjuicio generado por motivo del uso de las
      Funcionalidades o de los Recursos Digitales.
    </p>
    <p>
      Los servicios y/o productos de Terceros que están disponibles en conjunto
      por medio del Sitio Web, son proporcionados «tal y como están» y «conforme
      estén disponibles» sin garantías de las condiciones de cualquier tipo,
      sean expresas o implícitas. hasta el punto máximo permisible de
      conformidad con la legislación aplicable. AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES, sus proveedores, terceros contratantes del Sitio Web y demás
      proveedores, quedan eximidos de toda responsabilidad sobre las garantías y
      condiciones, expresas o implícitas, inclusive garantías implícitas y
      condiciones de comercialización adecuadas para un determinado objetivo y
      no en violación de derechos de propiedad.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, sus proveedores, terceros
      colaboradores de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES y demás
      proveedores no garantizan que las Funcionalidades y los Recursos Digitales
      sean ininterrumpidas o estén libres de errores, que los defectos sean
      corregidos, o que los servicios o el servidor que los pone a disposición
      esté libre de malware o de otros componentes perjudiciales, por lo que no
      serán responsables de cualesquiera pérdidas, daños o perjuicios sufridos
      por el Usuario o cualquier Tercero como resultado del uso del Sitio Web o
      cualquier otra interacción con los Enlaces.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, sus proveedores, terceros
      contratantes del Sitio Web y demás proveedores no garantizan ni hacen
      cualesquiera declaraciones relativas al uso o a los resultados de la
      utilización de las Funcionalidades o los Recursos Digitales en términos de
      precisión, confiabilidad o de cualquier otra forma. El Usuario asume todo
      el riesgo y costo de cualquier servicio necesario, arreglos o correcciones
      de cualquier naturaleza, sin que el Usuario pueda exigir el pago de dichos
      costos en momento posterior a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, sus
      proveedores o terceros colaboradores de AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES tampoco se responsabiliza, en
      ningún caso, por fraudes originados fuera o a través de su sistema y de
      sus computadoras, principalmente con relación a informaciones
      proporcionadas, sea en el Registro o en el Sitio Web.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES tampoco será responsable respecto
      de las pérdidas, daños y perjuicios sufridos por el Usuario o cualquier
      Tercero en relación con cualesquiera productos o servicios ofrecidos o
      divulgados, anuncios o materiales publicitarios que no sean proporcionados
      por AQUILES ENRIQUE FERNANDO SUÁREZ MONTES a través del Sitio Web o sus
      Enlaces.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES tomará todas las medidas posibles
      para mantener la confidencialidad y la seguridad de la información
      recibida por los Usuarios, pero no responderá por los daños o perjuicios
      que pudieran generarse derivado de violaciones a los sistemas de seguridad
      del Sitio Web por parte de Terceros y/u otros Usuarios. Asimismo, se
      invita a los Usuarios a leer el Aviso de Privacidad del Sitio Web.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES no garantiza la confiabilidad,
      integridad y exactitud respecto de todo tipo de contenido, incluyendo las
      Funcionalidades, los Recursos Digitales, y demás similar que se encuentre
      en el Sitio Web.
    </p>
    <p>
      Cualquier uso en el material presentado en el Sitio Web, incluyendo todo
      cualquier contenido visualizado en vivo, será realizado bajo el propio
      riesgo y responsabilidad del Usuario. El Usuario se obliga a indemnizar y
      a sacar en paz y a salvo a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES
      (incluyendo sus colaboradores y voluntarios), incluyendo cualesquier
      honorarios de abogados, generados por motivo de cualquier tipo de
      reclamación o demanda, derivados del mal uso por parte de dicho Usuario al
      material presentado en el Sitio Web.
    </p>
    <h3>2. Limitación de seguimiento de enlace</h3>
    <p>
      El usuario reconoce y acepta al ingresar a la compra del libro por
      cualquier enlace de Amazon.com.mx, que es una organización independiente
      de terceros patrocinadores y anunciantes cuya información, imágenes,
      anuncios y demás material publicitario o promocional esta fuera del
      alcance legal de esta página. El usuario reconoce y acepta que el material
      publicitario no forma parte del contenido principal que se publica en
      dicho sitio. Asimismo, reconoce y acepta con este acto que este material
      se encuentra protegido por las leyes que en materia de propiedad
      intelectual e industrial resulten aplicables.
    </p>
    <h3>3. Información General.</h3>
    <p>
      La información que intercambian los Usuarios con otros Usuarios o Terceros
      no es responsabilidad de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, aun
      cuando el intercambio se genere en el desarrollo de las Funcionalidades o
      los Recursos Digitales, siendo los Usuarios los únicos responsables
      respecto de la veracidad de dicha información por lo que se sugiere que
      nunca se provea información personal diferente o que no sea indispensable
      para hacer uso de las Funcionalidades o los Recursos Digitales.
    </p>
    <h3>VI. Mensajes.</h3>
    <p>
      El Usuario acepta expresamente que AQUILES ENRIQUE FERNANDO SUÁREZ MONTES
      o cualquiera de sus asociados envíen al Usuario mensajes por correo
      electrónico o SMS de carácter informativo, referentes a los productos y/o
      servicios ofertados en el Sitio Web, así como otros mensajes de naturaleza
      comercial tales como ofertas de Terceros contratantes y novedades del
      Sitio Web, incluyendo eventos. En caso de que el Usuario desease dejar de
      recibir los mencionados mensajes, deberá solicitar la cancelación de su
      envío en el propio Sitio Web.
    </p>
    <h3>VII. Uso de Cookies.</h3>
    <p>
      Los Usuarios y los Terceros reconocen y aceptan, dando su consentimiento
      total, pleno e irrevocable desde el momento de aceptar las presentes
      Condiciones, que el Sitio Web utiliza cookies propias y de terceros, las
      cuales se clasifican normalmente, en cookies “temporales” y cookies
      “permanentes”. Las cookies temporales suelen utilizarse para realizar la
      transmisión de información en una red de comunicaciones electrónica y son
      estrictamente necesarias para el desarrollo de las Funcionalidades y de
      los Recursos Digitales solicitados explícitamente por el Usuario, tales
      como las cookies de sesión que son el tipo más habitual de cookies
      temporales, mismas que ayudan al Usuario a navegar de forma eficiente por
      el Sitio Web, realizando un seguimiento del recorrido del Usuario por el
      Sitio Web, teniendo en cuenta la información que ha proporcionado durante
      la sesión iniciada con su Cuenta. Las cookies temporales se guardan en la
      memoria temporal del navegador que utilice el Usuario y se borran cuando
      éste se cierra. Las cookies permanentes, en cambio, guardan las
      preferencias del usuario para la sesión actual y futuras visitas. Las
      cookies permanentes suelen utilizarse para hacer un seguimiento del
      comportamiento del Usuario y facilitar una publicidad personalizada.
    </p>
    <h3>VIII. Modificaciones.</h3>
    <p>
      El Sitio Web se reserva el derecho de, a su exclusivo criterio, efectuar
      alteraciones a estas Condiciones sin necesidad de previo aviso. De esta
      forma, es recomendable que el Usuario vuelva a leer con regularidad este
      documento, de forma que se mantenga siempre informado sobre eventuales
      modificaciones.
    </p>
    <p>
      Si hubiese cualquier modificación al presente documento y el Usuario
      continuare utilizando el Sitio Web, las Condiciones serán consideradas
      leídas y aprobadas por el Usuario. Todas las alteraciones a las
      Condiciones se volverán efectivas inmediatamente después de su publicación
      en el Sitio Web, sin necesidad de cualquier aviso previo al Usuario.
    </p>
    <h3>IX. Vigencia y Rescisión.</h3>
    <p>El plazo de vigencia de las presentes Condiciones es indefinido.</p>
    <p>
      El Usuario podrá, en cualquier momento, cancelar el Registro por simple
      comunicación, solicitando a AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, a
      través del Sitio Web su exclusión del Registro, sin perjuicio de las
      obligaciones asumidas hasta la fecha de su exclusión.
    </p>
    <p>
      La rescisión de las presentes Condiciones no implicará, en ningún caso,
      que AQUILES ENRIQUE FERNANDO SUÁREZ MONTES deba indemnizar de forma alguna
      al Usuario.
    </p>
    <p>
      En caso de que un Usuario deje de serlo (por cancelación de cuenta o por
      decisión unilateral de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES), las
      disposiciones del último párrafo del Apartado I y el Apartado V se
      mantendrán vigentes por un plazo de 2 (dos) años contados a partir de la
      cancelación de la Cuenta o en los términos que establezca la ley
      aplicable.
    </p>
    <h3>X. Otras disposiciones.</h3>
    <p>
      1. Cesión. Este documento y cualesquiera derechos y licencias otorgadas
      aquí no podrán ser transferidos o cedidos por el Usuario, pero AQUILES
      ENRIQUE FERNANDO SUÁREZ MONTES sí estará en posibilidad de transferirlos o
      cederlos sin restricción alguna y sin necesidad de recabar el
      consentimiento previo del Usuario.
    </p>
    <p>
      2. Encabezados. Las referencias a encabezados existentes en las presentes
      Condiciones son hechas únicamente con fines de referencia y no serán
      consideradas para limitar o afectar cualquiera de las disposiciones aquí
      contenidas.
    </p>
    <p>
      3. Integridad de las Condiciones. El presente documento constituye el
      acuerdo integral entre el Usuario y AQUILES ENRIQUE FERNANDO SUÁREZ MONTES
      relacionado con el desarrollo de las Funcionalidades, de los Recursos
      Digitales y la utilización del Sitio Web y no podrá modificarse, salvo lo
      dispuesto en las presentes Condiciones.
    </p>
    <p>
      4. Legislación Aplicable. Las presentes Condiciones, así como la relación
      de AQUILES ENRIQUE FERNANDO SUÁREZ MONTES por sí misma o mediante el Sitio
      Web, con respecto de los Usuarios, se regirán exclusivamente por la
      legislación y las leyes aplicables en México.
    </p>
    <p>
      5. Jurisdicción. Las partes se someten a la jurisdicción de los Juzgados y
      Tribunales competentes de la Ciudad de México, México renunciando
      expresamente a cualquier otro fuero que les pudiere corresponder en virtud
      de sus domicilios o por cualquier otra causa.
    </p>
  </>
)

const TermsEng = (
  <>
    <h3>PROEM</h3>
    <p>
      By entering and using this website, whose domain name is
      https://tiraniapopulista.com, owned by Aquiles Enrique Fernando Suarez
      Montes, hereinafter referred to as "Populist Tyranny", the user is
      accepting the Terms and Conditions of Use contained in this agreement and
      expressly declares its acceptance using electronic means for this purpose,
      in terms of the provisions of Article 1803 of the Federal Civil Code.
    </p>
    <p>
      If the user does not fully and completely accept the terms and conditions
      of this agreement, the user must refrain from accessing, using and
      observing the https://tiraniapopulista.com website.
    </p>
    <p>
      In the event that the user accesses, uses and observes the website
      https://tiraniapopulista.com it shall be considered as an absolute and
      express acceptance of the Terms and Conditions of Use stipulated herein.
    </p>
    <p>
      The sole use of this website grants the general public the status of user
      (hereinafter referred to as the "user" or "users") and implies full and
      unconditional acceptance of each and every one of the general and specific
      conditions included in these Terms and Conditions of Use published by
      https://tiraniapopulista.com at the time the user accesses the website.
    </p>
    <p>
      Any modification to the present Terms and Conditions of Use will be made
      when the holder of the same, in this case "Aquiles Enrique Fernando Suárez
      Montes", considers it appropriate, being the sole responsibility of the
      user to ensure that he/she is aware of such modifications.
    </p>
    <h3>I. General Conditions.</h3>
    <h3>Object.</h3>
    <p>
      The present general terms and conditions of use (hereinafter the
      "Conditions") are intended to regulate the access and use made by those
      individuals or legal entities identified as users of the website
      https://tiraniapopulista.com or any of its extensions (hereinafter the
      "Website") which has the exclusive use of the author AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES.
    </p>
    <h3>2. Definitions.</h3>
    <p>
      For the purposes of clarity of these Conditions, the definitions of the
      words listed below and how they are to be understood are set forth, having
      the same meaning whether used in the singular or in the plural:
    </p>
    <p>
      • Populist Tyranny: Means DOGMATIC TYRANNY AND POPULIST TYRANNY, and/or
      Aquiles Enrique Fernando Suarez Montes, in his capacity as owner of the
      Website.
    </p>
    <p>
      • Terms and Conditions: Means these general terms and conditions of use.
    </p>
    <p>
      • Account: Means the means of identification of the User that may be
      created in Populist Tyranny by those persons with sufficient capacity,
      including those representing legal entities duly constituted and
      represented, who intend to make use of the tools that the Website offers
      under these Conditions.
    </p>
    <p>
      • Links: Means any other domain or hyperlink that can be accessed in any
      way through the Website.
    </p>
    <p>
      • Functionalities: Means all the tools, functionalities and assistance
      described in the corresponding point of these Conditions.
    </p>
    <p>• Mexico: Means United Mexican States.</p>
    <p>
      • Web Site: Internet address POPULIST TYRANNY. owned by Aquiles Enrique
      Fernando Suárez Montes.
    </p>
    <p>
      • Third Party: Means any person who is not a party to this site including
      its officers, employees, representatives or dependents in any way or the
      User.
    </p>
    <p>
      • User: Means any person, whether natural or legal, who is interested in
      making use of the Functionalities and Digital Resources contained in the
      Web Site, either by collaborating in providing legal advice or by making
      use of the Functionalities and Digital Resources.
    </p>
    <h3>3. Acceptance.</h3>
    <p>
      The sole use of the Web Site represents the full and unconditional
      acceptance by the Users of each and every one of these Conditions from the
      very moment the User accesses and makes use of the Web Site.
    </p>
    <h3>4. Licitness and Intellectual Property.</h3>
    <p>
      The Web Site will be used only for lawful purposes, in accordance and in
      compliance with the current and applicable legislation in Mexico, which
      may be modified from time to time. The content, information and/or
      material that Aquiles Enrique Fernando Suárez Montes makes available to
      the Users through its Web Site contains elements protected by intellectual
      property rules, including software protection, photographs, logos,
      designs, graphic images, music and sound, without this description
      implying any limitation of nature.
    </p>
    <p>
      Any violation of these Terms by a User (including, but not limited to the
      commission of acts that, in the sole discretion of Aquiles Enrique
      Fernando Suárez Montes, constitute abusive, inappropriate or unlawful
      conduct) shall give Aquiles Enrique Fernando Suárez Montes the right, at
      any time and without prior notice of any kind, to suspend or terminate the
      use of the Functionalities, Digital Resources and/or to withdraw or deny
      access to the Website and/or your Account to any User.
    </p>
    <h3>5. Prohibitions.</h3>
    <p>
      Any copying, distribution, transmission, retransmission, publication,
      printing, dissemination and/or commercial exploitation of the material
      and/or content made available to Users and the general public through the
      Web Site and/or its own or Third Party Accounts is strictly prohibited
      without the prior express written consent of Aquiles Enrique Fernando
      Suárez Montes or, if applicable, of the owner of the corresponding
      property rights.
    </p>
    <p>
      Failure to comply with the foregoing shall subject the offender to all
      civil claims and administrative and/or criminal penalties that may apply.
      Likewise, the publication and/or distribution of any material that is
      obscene, inappropriate, defamatory or that may violate the rights of any
      Third Party or that may be contrary to the general policies of the Website
      and/or the legal system in force is prohibited.
    </p>
    <h3>6. Capacity.</h3>
    <p>
      In case of legal entities using the Web Site, they must be duly
      constituted in terms of the legislation of legal entities and represented
      by their legal representative, as provided by these conditions and any
      other applicable legislation in force, including the damages that may be
      caused to Third Parties during the representation using the Web Site.
    </p>
    <h3>I. User.</h3>
    <h3>1. Visitors.</h3>
    <p>
      The visitors of the Web Site will be able to visualize the
      Functionalities; as well as to navigate through this one freely without
      the need to have an Account, although they cannot make total use of the
      above mentioned Functionalities.
    </p>
    <h3>II. Obligations of the parties.</h3>
    <h3>1. Obligations of the User.</h3>
    <p>
      The User's obligations, without detriment to those arising in the other
      sections of these Conditions, shall be the following:
    </p>
    <p>
      1. Perform all those acts in a timely manner as required by the User for
      the proper use of the Functionalities.
    </p>
    <p>
      2. Not to use the contents and products of the Web Site for the purpose of
      violating the law, morality, good customs, intellectual property
      regulations, dignity, private life, image, personal and family privacy.
    </p>
    <p>
      3. Not to use the Functionalities, Digital Resources, contents and
      products provided by Aquiles Enrique Fernando Suárez Montes to transmit or
      disseminate unlawful, defamatory, abusive, threatening, obscene, harmful,
      vulgar, libelous or otherwise objectionable material.
    </p>
    <p>
      4. Comply with all applicable laws regarding the transmission of data from
      Mexico.
    </p>
    <p>
      5. Not to gain or attempt to gain access to other network or computer
      systems connected to the contents and products of the Web Site.
    </p>
    <p>
      6. Not to interfere with or disrupt the Functionalities or the Digital
      Resources of the servers, networks connected to the Functionalities, the
      Digital Resources, contents and products provided through the Web Site or
      the Links.
    </p>
    <p>
      7. Not to post or transmit through the Website any content or information
      containing any religious or political propaganda.
    </p>
    <p>
      8. Avoid engaging in any conduct that may restrict or inhibit the use of
      the Website or Links by other Users or Third Parties.
    </p>
    <p>
      The User agrees to hold harmless Aquiles Enrique Fernando Suárez Montes,
      its affiliated companies, its suppliers, third parties that collaborate
      with Aquiles Enrique Fernando Suárez Montes and suppliers for any claims,
      processes, actions, as well as any other liabilities (including legal
      fees) arising from its use or misuse of the Website, the violation of
      these Conditions, the violation of the rights of any other person or
      entity, or any violation of the representations, warranties and agreements
      made herein by the User; as well as indemnify them for any applicable
      damages.
    </p>
    <h3>
      2. Commitments of POPULIST TYRANNY / Aquiles Enrique Fernando Suárez
      Montes.
    </h3>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES shall also be bound by the
      following commitments, without detriment to those arising in the other
      sections of these Conditions:
    </p>
    <p>
      1. To ensure the proper functioning of the Functionalities and Digital
      Resources to the Users in accordance with the Conditions.
    </p>
    <p>
      2. To have all the necessary licenses and authorizations for the operation
      of the Website, the Functionalities and the Digital Resources.
    </p>
    <h3>
      3. Continuity in the Web Site, Functionalities and Digital Resources.
    </h3>
    <p>
      The User acknowledges that there could be (i) interruptions in the use of
      the Functionalities and the Digital Resources or (ii) other events
      occurring for reasons not attributable to AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES in which the use and/or development of the Functionalities and the
      Digital Resources would be interrupted, suspended or stopped. In none of
      these cases, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES can be held
      responsible for any lost data and/or unsuccessful transactions during the
      transmission of information through the Internet.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES shall not be liable for
      interruptions or suspensions of the Functionalities or of the Digital
      Resources and Internet access caused by the failure of the
      telecommunications system, in the supply of electric power, acts of God or
      force majeure or an action of Third Parties that may disable the equipment
      that provides access to the Internet and therefore to the Web Site.
    </p>
    <p>
      The User acknowledges that in the development of the Functionalities and
      the Digital Resources, there are acts to be performed by Third Parties
      that AQUILES ENRIQUE FERNANDO SUÁREZ MONTES does not control, so the
      performance of such activities, the User releases AQUILES ENRIQUE FERNANDO
      SUÁREZ MONTES from all responsibility, in the understanding that AQUILES
      ENRIQUE FERNANDO SUÁREZ MONTES will try to ensure that the referred Third
      Party performs its activities entrusted for the correct development of the
      Functionalities and the Digital Resources.
    </p>
    <p>
      Therefore, AQUILES ENRIQUE FERNANDO SUÁREZ MONTES shall not be liable for
      any damage, harm or loss to the User caused by failures not attributable
      to AQUILES ENRIQUE FERNANDO SUÁREZ MONTES in the Functionalities and
      Digital Resources, in the server or in the Internet. AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES will not be responsible for any Malware that could
      infect or have infected the User's equipment as a consequence of the
      access, use or examination of the Web Site or as a result of any transfer
      of data, files, images, texts, or audios contained therein between Users.
      The Users shall not be able to impute any responsibility to AQUILES
      ENRIQUE FERNANDO SUÁREZ MONTES or demand payment of damages or prejudices,
      by virtue of technical difficulties or failures not attributable to
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES in the Functionalities, the Digital
      Resources or in the Internet.
    </p>
    <p>
      The Web Site may eventually be unavailable due to technical difficulties
      or Internet failures, or due to any other circumstance beyond the control
      of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES; in such cases, AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES will try to restore it as soon as possible without
      being held responsible for it. AQUILES ENRIQUE FERNANDO SUÁREZ MONTES
      shall not be liable for any error or omission contained in the Web Site.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES may, at any time and at its sole
      discretion, discontinue, suspend, terminate or alter the form of access to
      the Web Site with respect to any content, period of availability and
      equipment necessary for access and/or use of the Web Site. AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES reserves the right to discontinue the dissemination
      of any information, alter or eliminate transmission methods, alter the
      speed of data transmission or any other signal characteristics without the
      prior consent of the User.
    </p>
    <h3>4. Denial of warranties</h3>
    <p>
      The user agrees that the use of the website https://tiraniapopulista.com
      is at the user's own risk and that the services and products provided and
      offered therein are provided on an "as is" and "as available" basis.
      Populist Tyranny" does not warrant that the site will meet the user's
      requirements or that the services offered on the site will be
      uninterrupted, secure or error-free.
    </p>
    <p>
      Populist Tyranny" does not guarantee or endorse in any way the
      truthfulness, accuracy, legality, morality or any other characteristic of
      the content of the material published on the https://tiraniapopulista.com
      website.
    </p>
    <p>
      Populist Tyranny" disclaims all warranties and conditions, both express
      and implied, with regard to the services and information contained or
      available on or through this website; including, without limitation:
    </p>
    <p>
      • a) The availability of use of the website https://tiraniapopulista.com.
    </p>
    <p>
      • b) The absence of viruses, bugs, bugs, disabling or any other
      contaminating material or destructive functions in the information or
      programs available on or through this page or in general any failure in
      such site.
    </p>
    <p>
      • c) Notwithstanding the foregoing, Populist Tyranny or its suppliers may
      update the content of the page constantly, so the user is requested to
      take into account that some information advertised or contained in or
      through this website may be outdated and / or contain inaccuracies or
      typographical or spelling errors.
    </p>
    <h3>5. Limitations of liability</h3>
    <p>
      To the maximum extent permitted by applicable law, in no event shall
      Populist Tyranny be liable for any direct, special, incidental, indirect,
      or consequential damages in any way arising out of or related to:
    </p>
    <p>
      • a) The use or performance of the website https://tiraniapopulista.com,
      with the delay or unavailability of use of "Amazon".
    </p>
    <p>
      • b) The provision or lack thereof of services of any information or
      graphics contained or published on or through the aforementioned site.
    </p>
    <p>• c) The updating or failure to update the information.</p>
    <p>
      • d) The alteration or modification, in whole or in part, of the
      information after it has been included in said site.
    </p>
    <p>
      • e) Any other aspect or characteristic of the information contained or
      published on the web page or through the links that may be included in
      this site.
    </p>
    <p>
      • f) The provision or failure to provide other services, all of the
      foregoing shall apply, even in cases where Amazon" has been notified or
      advised of the possibility of such damages.
    </p>
    <h3>III. Ownership and Rights in the Content of the Web Site.</h3>
    <h3>Copyright protection.</h3>
    <p>
      Users are advised that the rights to the content published by AQUILES
      ENRIQUE ENRIQUE FERNANDO SUÁREZ MONTES on the Web Site may be protected by
      the Mexican legislation in force regarding copyrights, intellectual and
      industrial property. All content available on the Web Site, including
      Links, are the exclusive property of AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES or the author of the published material. AQUILES ENRIQUE FERNANDO
      SUÁREZ MONTES does not authorize Users and third parties to distribute
      and/or transmit the material found on the Web Site whose authorship
      belongs to AQUILES ENRIQUE FERNANDO SUÁREZ MONTES. It is forbidden any
      kind of modification of information or Links without the express
      authorization of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES or of the author
      of such rights, previous written notification of the owner, as well as the
      undue attribution, either partial or total of the information or content
      without the express authorization of AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES or of the owner of such rights.
    </p>
    <p>
      Any violation of the provisions of this paragraph shall constitute an
      infringement of copyright and intellectual property rights and shall
      subject the User to the applicable administrative, civil and criminal
      penalties.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES reserves all rights not expressly
      granted herein. The User declares and acknowledges that the downloading of
      any content from the Web Site does not confer ownership of any trademarks
      displayed on the Web Site.
    </p>
    <p>
      The User shall not upload, publish or otherwise make available on the
      Website and/or its Account any material protected by copyright, trademark
      registration or any other intellectual property right without prior
      express authorization from the owner or licensee of such right.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES has no duty or responsibility to
      provide the User with any indications that help to identify the content as
      protected will be solely responsible for any damages to Third Parties
      resulting from the violation of intellectual property rights, by virtue of
      the use of the aforementioned content.
    </p>
    <h3>IV. Responsibility.</h3>
    <h3>1. Limits of Liability.</h3>
    <p>
      By virtue of the fact that AQUILES ENRIQUE FERNANDO SUÁREZ MONTES only
      provides the Functionalities and the Digital Resources described in the
      present Conditions of onerous character, it is not responsible for the
      products and/or services offered by Third Parties, including those made by
      Third Parties involved in the development of the Functionalities or of the
      Digital Resources, so that no User or Third Party may make any claim to
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES about any defect, damage and/or
      harm generated by reason of the use of the Functionalities or of the
      Digital Resources.
    </p>
    <p>
      The services and/or products of Third Parties that are available
      collectively through the Web Site are provided on an "as is" and "as
      available" basis without warranties of any kind, whether express or
      implied, to the fullest extent permissible under applicable law. AQUILES
      ENRIQUE FERNANDO SUÁREZ MONTES, its suppliers, third party contractors of
      the Web Site and other suppliers, are released from all liability for
      warranties and conditions, express or implied, including implied
      warranties and conditions of merchantability and fitness for a particular
      purpose and non-infringement of proprietary rights.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, its suppliers, third party
      collaborators of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES and other
      suppliers do not warrant that the Functionalities and Digital Resources
      will be uninterrupted or error-free, that defects will be corrected, or
      that the services or the server that makes them available are free of
      malware or other harmful components, and therefore shall not be liable for
      any loss, damage or harm suffered by the User or any Third Party as a
      result of the use of the Web Site or any other interaction with the Links.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, its suppliers, third party
      contractors of the Web Site and other suppliers do not warrant or make any
      representations regarding the use or the results of the use of the
      Functionalities or the Digital Resources in terms of accuracy, reliability
      or otherwise. The User assumes the entire risk and cost of any necessary
      service, repairs or corrections of any nature, without the User being able
      to demand payment of such costs at a later time from AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES, its suppliers or AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES' third party collaborators.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES is not responsible, in any case,
      for frauds originated outside or through its system and computers, mainly
      in relation to information provided, either in the Register or in the Web
      Site.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES shall not be liable for any loss or
      damage suffered by the User or any Third Party in connection with any
      products or services offered or disclosed, advertisements or advertising
      materials that are not provided by AQUILES ENRIQUE FERNANDO SUÁREZ MONTES
      through the Web Site or its Links.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES shall take all possible measures to
      maintain the confidentiality and security of the information received by
      the Users, but shall not be liable for any damages that may arise from
      violations to the Website's security systems by Third Parties and/or other
      Users. Likewise, Users are invited to read the Website's Privacy Notice.
    </p>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES does not guarantee the reliability,
      completeness and accuracy of all content, including the Functionalities,
      Digital Resources, and other similar content found on the Web Site.
    </p>
    <p>
      Any use of the material presented on the Web Site, including any content
      displayed live, is at the User's own risk and responsibility. The User
      agrees to indemnify and hold harmless AQUILES ENRIQUE FERNANDO FERNANDO
      SUÁREZ MONTES (including its collaborators and volunteers), including any
      attorney's fees, arising from any claim or lawsuit, derived from the
      misuse by such User of the material presented on the Website.
    </p>
    <h3>2. Link tracking limitation</h3>
    <p>
      The user acknowledges and agrees by entering the purchase of the book
      through any Amazon.com.mx link, that it is an independent organization
      from third party sponsors and advertisers whose information, images,
      advertisements and other advertising or promotional material is outside
      the legal scope of this page.
    </p>
    <p>
      The user acknowledges and agrees that the advertising material is not part
      of the main content published on this site. The user also acknowledges and
      accepts that this material is protected by the applicable intellectual and
      industrial property laws.
    </p>
    <h3>3. General Information.</h3>
    <p>
      The information exchanged by Users with other Users or Third Parties is
      not the responsibility of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, even
      when the exchange is generated in the development of the Functionalities
      or the Digital Resources, being the Users the only ones responsible for
      the veracity of such information, so it is suggested that they never
      provide different personal information or information that is not
      indispensable to make use of the Functionalities or the Digital Resources.
    </p>
    <h3>V. Messages.</h3>
    <p>
      The User expressly accepts that AQUILES ENRIQUE FERNANDO SUÁREZ MONTES or
      any of its associates may send the User messages by e-mail or SMS of an
      informative nature, referring to the products and/or services offered on
      the Website, as well as other messages of a commercial nature such as
      offers from Third Party contractors and news from the Website, including
      events. In the event that the User wishes to stop receiving the
      aforementioned messages, he/she must request the cancellation of their
      sending on the Website itself.
    </p>
    <h3>VI. Use of Cookies.</h3>
    <p>
      Users and Third Parties acknowledge and accept, giving their full, full
      and irrevocable consent from the moment of accepting these Conditions,
      that the Website uses its own and third party cookies, which are normally
      classified into "temporary" cookies and "permanent" cookies. Temporary
      cookies are usually used to carry out the transmission of information in
      an electronic communications network and are strictly necessary for the
      development of the Functionalities and Digital Resources explicitly
      requested by the User, such as session cookies which are the most common
      type of temporary cookies, which help the User to navigate efficiently
      through the Website, tracking the User's path through the Website, taking
      into account the information provided during the session initiated with
      your Account. Temporary cookies are stored in the temporary memory of the
      browser used by the User and are deleted when the browser is closed.
      Permanent cookies, on the other hand, save the User's preferences for the
      current session and future visits. Persistent cookies are typically used
      to track User behavior and facilitate targeted advertising.
    </p>
    <h3>VII. Modifications.</h3>
    <p>
      The Website reserves the right, at its sole discretion, to make changes to
      these Terms and Conditions without prior notice. Therefore, it is
      recommended that the User re-read this document regularly, so that he/she
      is always informed of any changes.
    </p>
    <p>
      If there is any modification to the present document and the User
      continues to use the Website, the Conditions shall be deemed read and
      approved by the User. All alterations to the Conditions shall become
      effective immediately upon their publication on the Website, without the
      need for any prior notice to the User.
    </p>
    <h3>VIII. Term and Termination.</h3>
    <p>The term of these Conditions is indefinite.</p>
    <p>
      The User may, at any time, cancel the Registration by simple
      communication, requesting AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, through
      the Web Site his exclusion from the Register, without prejudice to the
      obligations assumed up to the date of his exclusion.
    </p>
    <p>
      The termination of these Conditions shall not imply, in any case, that
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES should compensate the User in any
      way.
    </p>
    <p>
      In the event that a User ceases to be a User (by account cancellation or
      by unilateral decision of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES), the
      provisions of the last paragraph of Section I and Section V shall remain
      in effect for a period of 2 (two) years as of the cancellation of the
      Account or under the terms established by applicable law.
    </p>
    <h3>IX. Other provisions.</h3>
    <p>
      1. Assignment. This document and any rights and licenses granted herein
      may not be transferred or assigned by the User, but AQUILES ENRIQUE
      FERNANDO SUÁREZ MONTES will be able to transfer or assign them without any
      restriction and without the need to obtain the prior consent of the User.
    </p>
    <p>
      2. Headings. References to headings in these Terms are for reference
      purposes only and shall not be deemed to limit or affect any of the
      provisions contained herein.
    </p>
    <p>
      3. Integrity of the Conditions. The present document constitutes the
      integral agreement between the User and AQUILES ENRIQUE FERNANDO SUÁREZ
      MONTES related to the development of the Functionalities, the Digital
      Resources and the use of the Web Site and may not be modified, except as
      provided in the present Conditions.
    </p>
    <p>
      4. Applicable Legislation. The present Conditions, as well as the
      relationship of AQUILES ENRIQUE FERNANDO SUÁREZ MONTES by itself or
      through the Web Site, with respect to the Users, shall be governed
      exclusively by the legislation and laws applicable in Mexico.
    </p>
    <p>
      5. Jurisdiction. The parties submit to the jurisdiction of the competent
      Courts and Tribunals of Mexico City, Mexico, expressly waiving any other
      jurisdiction that may correspond to them by virtue of their domiciles or
      for any other reason.
    </p>
  </>
)

export const labels = {
  acceptButton: [
    'he leído y acepto los términos y condiciones',
    'I have read and agree to the terms and conditions',
  ],
  acceptPrivacy: [
    'he leído y acepto el acuerdo de privacidad',
    'I have read and agree to the privacy terms',
  ],
}

export const terms = [TermsEsp, TermsEng]
