export const labels = {
  titulo1: ['Adquiere', 'Get'],
  titulo2: [
    'TIRANÍA DOGMÁTICA Y TIRANÍA POPULISTA',
    'DOGMATIC TYRANNY AND POPULIST TYRANNY',
  ],
  titulo3: [
    ' en sus diversas presentaciones',
    'in their various presentations',
  ],
  titulo4: ['Edición español pasta blanda', 'Spanish edition paperback book'],
  titulo5: ['Edición español pasta dura', 'Spanish edition hardcover book'],
  titulo6: ['Edición español versión Kindle', 'Spanish edition Kindle version'],
  titulo7: ['Edición inglés versión Kindle', 'English edition Kindle versión'],
  titulo8: ['Edición inglés pasta blanda', 'English edition paperback book'],
  titulo9: ['Edición inglés pasta dura', 'English edition hardcover book'],
}
