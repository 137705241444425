const textEsp = (
  <>
    La filosofía del autor se basa en la razón, la ciencia, el aprendizaje a
    través de los relatos históricos y la experiencia personal, siendo los
    soportes éticos y morales que pueden darle sentido a la vida. Igualmente se
    centra en el cuidado y conservación del medio ambiente y en un utilitarismo
    ampliado y sostenible encauzado a buscar los medios para lograr la dignidad
    humana, privilegiando el concepto de lo humano por encima de consideraciones
    materiales. La individualidad en lugar de la masificación humana.
  </>
)

const textEng = (
  <>
    The author's philosophy is based on reason, science, learning through
    historical accounts, personal experience, and on being ethical and moral
    supports that can give meaning to life. It also focuses on the care and
    conservation of the environment and on an expanded and sustainable
    utilitarianism aimed at finding the means to achieve human dignity,
    emphasizing the concept of the human above material
    considerations—individuality instead of human massification.
  </>
)

export const labels = {
  optCompra: ['Opciones de compra en Amazon', 'Buying options on Amazon'],
  edicion: ['Edición', 'edition'],
  texto: [textEsp, textEng],
}
