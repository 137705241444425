import React, { useState, useEffect } from 'react'
import { Card, Button, Container, Row, Col } from 'react-bootstrap'
import { labels } from './labels.js'
import imgPortadaEspanol from '../../assets/img/portadaespanol.jpg'
import imgPortadaIngles from '../../assets/img/portadaIngles.png'

const VentaLibro = ({ libros, idioma, titulo }) => {
  const [urlFoto, setUrlFoto] = useState(null)
  const [selectedOption, setSelectedOption] = useState()

  const handleOptionSelect = (option) => {
    setSelectedOption(option)
    console.log({ option })
  }

  useEffect(() => {
    const image = idioma ? imgPortadaIngles : imgPortadaEspanol
    setUrlFoto(image)
  }, [idioma])

  return (
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <Card.Img
            variant="top"
            alt="Tiranía dogmatica y tiranía populista portada"
            src={urlFoto}
          />
        </Col>
        <Col xs={12} md={8}>
          <Card>
            <Card.Body>
              <Card.Title>{titulo}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Enrique Suárez
              </Card.Subtitle>
              <Card.Title>{labels.edicion[idioma]}:</Card.Title>
              <Card.Subtitle>{selectedOption?.titulo}</Card.Subtitle>
              <Card.Subtitle className="mt-3">
                {labels.texto[idioma]}
              </Card.Subtitle>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>{labels.optCompra[idioma]}</Card.Header>
            <Card.Body>
              {libros.map((option, index) => (
                <Button
                  key={index}
                  variant={
                    option === selectedOption ? 'primary' : 'outline-primary'
                  }
                  onClick={() => handleOptionSelect(option)}
                  className="m-2"
                  href={option.url}
                  target="_blank"
                >
                  {option.titulo}
                </Button>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default VentaLibro
