export const labels = {
  titulo1: [
    'Tiranía Dogmática y Tiranía Populista',
    'Dogmatic Tyranny and Populist Tyranny',
  ],
  titulo2: ['por Enrique Suárez', 'by Enrique Suárez'],
  titulo3: ['Legales', 'Legal'],
  titulo4: ['Términos y condiciones', 'Terms and conditions'],
  titulo5: ['Aviso de privacidad', 'Notice of privacy'],
  titulo6: ['Acerca de Enrique Suárez', 'About Enrique Suárez'],
  titulo7: [
    'Tiranía Dogmática y Tiranía Populista por Enrique Suárez 2023',
    'Dogmatic Tyranny and Populist Tyranny by Enrique Suárez 2023',
  ],
  titulo8: ['Todos los derechos reservados', 'All right reserved'],
  facebook: ['Síguenos en Facebook', 'Follow Us on Facebook'],
}
