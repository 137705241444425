import { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Imagen from '../Imagen'

import imgPortadaEspanol from '../../assets/img/portadaespanol.jpg'
import imgPortadaIngles from '../../assets/img/portadaIngles.png'
import { labels, url } from './labels'

function ParrafosImagen({ idioma }) {
  const [urlIdioma, setUrlIdioma] = useState(null)

  useEffect(() => {
    console.log('idioma:', idioma)

    const url = idioma === 1 ? imgPortadaIngles : imgPortadaEspanol

    setUrlIdioma(url)
  }, [idioma])

  return (
    <Container fluid className="seccion-4" id="libro">
      <Row className="flex-column-reverse flex-lg-row">
        <Col lg={6} className="mt-lg-5">
          <h2 className=" titulo-libro">{labels.titulo[idioma]}</h2>
          <p>{labels.parrafo1[idioma]}</p>
          <p>{labels.parrafo2[idioma]}</p>
          <p>{labels.parrafo3[idioma]}</p>
          <Button
            type="warning"
            href={url[idioma]}
            target="_blank"
            variant="primary"
          >
            {labels.boton1[idioma]}
          </Button>
        </Col>
        <Col className="img-libro-container mt-5">
          <Imagen url={urlIdioma} className="img-libro" />
        </Col>
      </Row>
    </Container>
  )
}

export default ParrafosImagen
