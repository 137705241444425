export const frases = [
  {
    frase: [
      'La duda razonada es la gran virtud de la humanidad',
      'Reasoned doubt is the great virtue of humanity',
    ],
  },
  {
    frase: [
      'La fe dogmática es el gran defecto de la humanidad',
      'Dogmatic faith is the great defect of humanity',
    ],
  },
]
