export const privacidadEsp = (
  <>
    <h3>AVISO DE PRIVACIDAD</h3>
    <p>
      AQUILES ENRIQUE FERNANDO SUÁREZ MONTES, mejor conocido como ENRIQUE SUÁREZ
      MONTES con domicilio ubicado en Vía Ecónomos Número 5443, Jardines de
      Guadalupe, Municipio de Zapopan, Jalisco, C.P. 45030, en la entidad de
      JALISCO, país MEXICO, autor del libro titulado TIRANÍA DOGMÁTICA Y TIRANÍA
      POPULISTA, propietario del portal de internet
      https://tiraniapopulista.com, acorde a su giro de Autor de Libros en
      género Didáctico, al respecto le informamos lo siguiente, de conformidad a
      lo dispuesto por los artículos 15 y 16 de la Ley Federal de Protección de
      Datos Personales en Posesión de Particulares y otras disposiciones
      emanadas o relacionadas a ella, le notifica que se utilizarán sus datos
      personales recabados para la verificación de la información de los
      clientes y lectores, ventas, creación de nuevos libros y materiales,
      conferencias y presentaciones, recordatorios y seguimiento de posteriores
      a compras, postventa, levantamiento y confirmación de pedidos,
      aclaraciones, publicidad e información sobre productos enviados,
      cotizaciones, promociones y boletines, correos electrónicos para
      seguimiento de compras, aclaración y verificación de datos, estadística de
      ventas y estadísticas y proyecciones para efectos publicitarios.
    </p>
    <p>
      En https://tiraniapopulista.com la privacidad de los datos siempre tendrá
      carácter de información confidencial, manteniendo de la misma manera la
      privacidad que recae en sus datos personales; mediante nuestras políticas
      y medidas de seguridad administrativas se brindará y creará la confianza
      máxima de la protección de sus datos. Las únicas personas que tendrán
      acceso a su información confidencial serán personas autorizadas y/o que
      administran la página web y que por su actividad utilizan necesariamente
      estos datos, para así evitar la pérdida, daño, alteración, destrucción,
      venta, renta, uso, acceso o divulgación indebida.
    </p>
    <p>
      Los Datos Personales recabados de todos los clientes serán para cumplir
      con las finalidades señaladas en el Aviso de Privacidad Integral que puede
      ser consultado en el sitio web del autor: https://tiraniapopulista.com
    </p>
    <p>
      La información de datos personales obtenidos por
      https://tiraniapopulista.com, serán tratados con base en los principios de
      licitud, consentimiento, información, calidad, finalidad, lealtad,
      proporcionalidad y responsabilidad, de conformidad con la Ley Federal de
      Protección de Datos Personales en Posesión de los Particulares, su
      Reglamento y sus Lineamientos; los Datos son recabados con la finalidad de
      brindarle al cliente productos de una forma más eficaz.
    </p>
    <p>
      La aceptación del aviso de privacidad y no demostrar una oposición
      solicitada de uso y tratamiento de sus datos, implica que los datos
      personales han sido otorgados voluntariamente y que, de manera informada,
      ha dado su consentimiento. De la misma manera, el titular reconoce como su
      responsabilidad la autenticidad y actualidad de los mismos. Por tal
      motivo, la información proporcionada por el usuario deberá ser veraz y
      completa.
    </p>
    <p>
      Usted tiene derecho a conocer qué datos personales tenemos de usted, para
      qué los utilizamos y las condiciones del uso que les damos (Acceso).
      Asimismo, es su derecho solicitar la corrección de su información personal
      en caso de que esté desactualizada, sea inexacta o incompleta
      (Rectificación); que la eliminemos de nuestros registros o bases de datos
      cuando considere que la misma no está siendo utilizada adecuadamente
      (Cancelación); así como oponerse al uso de sus datos personales para fines
      específicos (Oposición). Estos derechos se conocen como derechos ARCO.
    </p>
    <p>
      Para el ejercicio de cualquiera de los derechos ARCO, usted deberá
      presentar la solicitud del Formato de Solicitud de Derechos ARCO, el cual
      debe ser requerido a la dirección de correo electrónico
      contacto@tiraniapopulista.com
    </p>
    <p>
      Para más información acerca del tratamiento y de los derechos que puede
      hacer valer, así como acceder al conocimiento de sus derechos ARCO, puede
      usted obtener mayor información mediante la visita al sitio
      http://inicio.ifai.org.mx
    </p>
    <p>
      Usted puede acceder a nuestro aviso de privacidad integral a través del
      portal de internet https://tiraniapopulista.com o solicitarlo al Contacto:
      Enrique Suárez Montes, Telefono: 331-3634-663 o mediante correo
      electrónico contacto@tiraniapopulista.com
    </p>
  </>
)
