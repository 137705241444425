import Image from "react-bootstrap/Image";


function Imagen({url, className }) {

   



  return (
    <Image
      alt="Tirania Dodmatica y tirania populista"
      className={className}
      src={url}
    ></Image>
  );
}
export default Imagen;
