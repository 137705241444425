export const labels = {
  parrafo1: [
    'Enrique Suárez Montes, nació en San Pedro, Tlaquepaque, Jalisco, México. Es Ingeniero Civil por la Universidad de Guadalajara, UdeG, con un posgrado en la División del Doctorado de la UNAM.',
    'Enrique Suárez Montes was born in San Pedro Tlaquepaque, Jalisco, México. He is a Civil Engineer from the University of Guadalajara (UdG) with a postgraduate degree from the Doctorate Division of the UNAM.',
  ],
  parrafo2: [
    'Se ha desarrollado profesionalmente como profesor de la Universidad de Guadalajara, como empresario y en el servicio público: en el ámbito federal y municipal.',
    'He has developed professionally as a professor at the University of Guadalajara, as a businessman, and in public service at the federal and municipal levels.',
  ],
  parrafo3: [
    'Es autor de un libro relacionado con la Ingeniería Civil y otros cuatro titulados: La mente y sus inquietudes, Atrévete a pensar, Camino a un mundo mejor y el presente Tiranía Dogmática y Tiranía Populista dentro del marco de las tendencias contemporáneas.',
    'He is the author of one book related to Civil Engineering and four others entitled: La mente y sus inquietudes, Atrévete a pensar, Camino a un mundo mejor, and the present Dogmatic Tyranny and Populist Tyranny (Tiranía populista y Tiranía dogmática) within the framework of contemporary trends',
  ],
  parrafo4: [
    'Ha publicado artículos y participado en diferentes foros relacionados con la educación a nivel universitario. Además de su inclinación por temas relacionados con la Ingeniería Civil y la educación, ha mantenido una inquietud por todo lo relacionado con la existencia incluido el humanismo y la ecología.',
    'He has published articles and participated in different forums related to education at the university level. In addition to his inclination for civil engineering and education topics, he has maintained a concern for everything related to existence, including humanism and ecology.',
  ],
}
