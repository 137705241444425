import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Imagen from '../Imagen'
import TextoBotones from '../TextoBotones'
import imgFotoespañol from '../../assets/img/fotoespanol.jpg'
import imgFotoingles from '../../assets/img/fotoingles.jpg'

function ContenedorImagenTexto({ idioma }) {
  const [urlFoto, setUrlFoto] = useState(null)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768)

  useEffect(() => {
    const image = idioma ? imgFotoingles : imgFotoespañol
    setUrlFoto(image)
  }, [idioma])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Container fluid className="seccion-1 my-5" id="home">
      <Row className="align-items-center">
        <Col className="img-container" xs={12} md={6}>
          <Imagen
            idioma={idioma}
            className="img-portada"
            url={urlFoto}
            alt={
              idioma ? 'English cover image' : 'Imagen de portada en español'
            }
            title={
              idioma ? 'English cover image' : 'Imagen de portada en español'
            }
            style={{ objectFit: 'cover', height: isDesktop ? '100%' : 'auto' }}
          />
        </Col>
        <Col className="titulo-pagina" xs={12} md={6}>
          <TextoBotones idioma={idioma} />
        </Col>
      </Row>
    </Container>
  )
}

export default ContenedorImagenTexto
