export const labels = {
  parrafo1: [
    'Uno de los temas centrales del libro es atraer al lector a que haga uso de sus capacidades de razonamiento y pensamiento abstracto, desterrando los dogmas y abriéndole la puerta al pensamiento analítico y reflexivo.',
    "Trying to summarize, the book's central theme (not the only one) is to attract the reader to use the neocortex's capacities: reasoning and abstract thinking.",
  ],
  parrafo2: [
    'Siguiendo el derrotero del razonamiento doy respuesta a algunas de las interrogantes que gravitan en el ser humano. Se enuncian diez postulados, el último relacionado con el populismo. Estos postulados son respaldados por ulteriores consideraciones.',
    'Following the path of reasoning, I answer some of the questions that gravitate in the human being. Ten postulates are stated, the last one related to populism. These postulates are supported by further considerations.',
  ],
  parrafo3: [
    'Para lograr lo anterior incursioné, entre otros, en temas relacionados con: el cerebro y sus filtros, una creación probabilística sustentada en las grandes cifras, el universo, las singularidades de la Tierra, los mandatos de la vida y como tema relevante la educación. Por último, se examina la decadencia democrática liderada por la tiranía populista.',
    'To achieve the above, I made incursions, among other topics, related to: the brain and its filters, a probabilistic creation (cosmos and life) supported by the big numbers, the universe, the singularities of the Earth, the mandates of life, and, as a relevant topic, education, which includes an analysis of the conscious and the unconscious, besides other relevant considerations; finally, the democratic decadenceled by populist tyranny is examined.',
  ],
  titulo: [
    'Tiranía Dogmática y Tiranía Populista',
    'Dogmatic Tyranny and Populist Tyranny',
  ],
  boton1: ['Conoce el libro', 'Know the book'],
}

export const url = [
  'https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-populista-Spanish/dp/B0B5KQ49WZ/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=&asin=B0B5ZBH3S5&revisionId=c402b6b8&format=1&depth=1',
  'https://www.amazon.com.mx/Dogmatic-Tyranny-Populist-English-ebook/dp/B0BJRWRT7R/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3U37RWV3K7GFB&keywords=dogmatic+tyranny+and+populist+tyranny&qid=1680917177&s=digital-text&sprefix=dogmatic+tyranny+and+populist+tyranny%2Cdigital-text%2C139&sr=1-1&asin=B0BJRWRT7R&revisionId=b2bae95d&format=1&depth=1',
]
