import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { labels } from './labels';

// import Slider from 'react-slick'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import VentaLibro from '../ventaLibro';

export default function TextoLinks({ idioma }) {
  // const [numOfSliders, setNumOfSliders] = useState(0)

  const LIBROS = [
    {
      idioma: 0,
      titulo: labels.titulo4[idioma],
      url: 'https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-populista-Spanish/dp/B0B5PLCRW7/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=',
    },
    {
      idioma: 0,
      titulo: labels.titulo5[idioma],
      url: 'https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-populista-Spanish/dp/B0B5KQ49WZ/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=',
    },
    {
      idioma: 0,
      titulo: labels.titulo6[idioma],
      url: 'https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-y-populista-ebook/dp/B0B5ZBH3S5/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=&sr=',
    },
    {
      idioma: 1,
      titulo: labels.titulo7[idioma],
      url: 'https://www.amazon.com.mx/Dogmatic-Tyranny-Populist-English-ebook/dp/B0BJRWRT7R/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3U37RWV3K7GFB&keywords=dogmatic+tyranny+and+populist+tyranny&qid=1680917177&s=digital-text&sprefix=dogmatic+tyranny+and+populist+tyranny%2Cdigital-text%2C139&sr=1-1',
    },
    {
      idioma: 1,
      titulo: labels.titulo8[idioma],
      url: 'https://www.amazon.com.mx/Dogmatic-Tyranny-Populist-Spanish/dp/B0BJTVH3W3/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr=',
    },
    {
      idioma: 1,
      titulo: labels.titulo9[idioma],
      url: 'https://www.amazon.com.mx/Dogmatic-Tyranny-Populist-Spanish/dp/B0BJTFPQ1F/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=',
    },
  ];

  const [optionSelected] = useState(
    LIBROS.find((libro) => libro.idioma === idioma)
  );

  return (
    <Row className="fondo-contenedor" id="comprar">
      <h2 style={{ marginBottom: '3vh' }}>{labels.titulo1[idioma]}</h2>
      <h1 className="titulo-libro">{labels.titulo2[idioma]}</h1>
      <h2 style={{ marginBottom: '4vh' }}>{labels.titulo3[idioma]}</h2>

      <VentaLibro
        idioma={idioma}
        libros={LIBROS.filter((libro) => libro.idioma === idioma)}
        titulo={labels.titulo2[idioma]}
        optionSelected={optionSelected}
      />
    </Row>
  );
}
