import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { labels } from '../../utils/terminosYcondiciones'
import { Button } from 'react-bootstrap'
import { privacidadEsp } from '../../utils/AvisodePrivacidad'
import privacy from '../../assets/privacy.pdf'

function ModalPrivacy({ show, setShow, titulo, idioma }) {
  const handleAcceptTerms = () => {
    window.localStorage.setItem('accept-privacy', true)

    setShow(false)
  }

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={show}
        fullscreen={false}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="terms">{privacidadEsp}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button href={privacy} target="_blank" variant="secondary">
            Ver completo
          </Button>
          <Button variant="success" onClick={handleAcceptTerms}>
            {labels.acceptPrivacy[idioma]}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalPrivacy
