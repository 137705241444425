import Home from './pages/home';
import './App.css';

function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
