import { useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import { labels } from './labels'
import ModalTC from '../Modal/modal'
import privacy from '../../assets/privacy.pdf'
import qrCode from '../../assets/img/frame.png'

import fbLogo from '../../assets/img/facebook.svg'

export default function Footer({ idioma }) {
  const [show, setShow] = useState(false)

  function mostrarModal() {
    setShow(true)
  }

  return (
    <Row className="footer">
      <Col>
        <h4>{labels.titulo1[idioma]}</h4>
        <h5>{labels.titulo2[idioma]}</h5>
        <Image src={qrCode} className="qr-image" />
      </Col>
      <Col>
        <h5>{labels.titulo3[idioma]}</h5>

        <h6>
          <span onClick={mostrarModal}>{labels.titulo4[idioma]}</span>
        </h6>
        {idioma ? null : (
          <h6>
            <a href={privacy} target="_blank" rel="noopener noreferrer">
              {labels.titulo5[idioma]}
            </a>
          </h6>
        )}
        <h6>
          <a href="#autor">{labels.titulo6[idioma]}</a>
        </h6>
      </Col>
      <Col>
        <h6>{labels.titulo7[idioma]}</h6>
        <h6>{labels.titulo8[idioma]}</h6>
        <a
          href="https://www.facebook.com/profile.php?id=100063739355808"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fbLogo} alt="Facebook Page" width="25px" />{' '}
          {labels.facebook[idioma]}
        </a>
      </Col>
      <ModalTC
        titulo="Términos y Condiciones"
        show={show}
        setShow={setShow}
        idioma={idioma}
      />
    </Row>
  )
}
