import { Col, Container, Row, Button } from 'react-bootstrap'
import { labels } from './labels'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faCartShopping } from '@fortawesome/free-solid-svg-icons'

function TextoBotones({ idioma }) {
  return (
    <>
      <Container className="contenedor-texto-dosbotones">
        <Row className="text-center my-4">
          <Col>
            <h2 className="titulo-libro">{labels.titulo[idioma]}</h2>
            <h2>{labels.subtitulo[idioma]}</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={6} className="my-4">
            <Button
              variant="secondary"
              size="lg"
              className="w-100"
              href="#libro"
            >
              <FontAwesomeIcon icon={faBookOpen} /> {labels.boton1[idioma]}
            </Button>
          </Col>

          <Col md={6} className="my-4">
            <Button
              href="https://www.amazon.com.mx/Tiran%C3%ADa-dogm%C3%A1tica-populista-Spanish/dp/B0B5KQ49WZ/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=&sr="
              target="_blank"
              rel="noopener noreferrer"
              variant="warning"
              size="lg"
              className="w-100"
            >
              <FontAwesomeIcon icon={faCartShopping} /> {labels.boton2[idioma]}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TextoBotones
