import React, { useEffect, useState } from 'react'
import ContenedorImagenTexto from '../../components/contenedorImagenTexto'
import ImagenParrafo from '../../components/ImagenParrafos'
import NavbarPage from '../../components/navbar'
import ParrafoBoton from '../../components/ParrafoBoton'
import ParrafosImagen from '../../components/ParrafosImagen'
import TextoLinks from '../../components/TextoLinks'
import Footer from '../../components/Footer'
import { Container } from 'react-bootstrap'
import FrasesCarrousel from '../../components/frasesCarrousel'
import ModalTC from '../../components/Modal/modal'
import ModalPrivacy from '../../components/Modal/modalPrivacy'

const termsTitle = ['Términos y condiciones', 'Terms and Conditions']

export default function Home() {
  const [show, setShow] = useState(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [idioma, setIdioma] = useState(0)
  const pathname = window.location.pathname

  useEffect(() => {
    if (pathname === '/en') setIdioma(1)
  }, [pathname])

  useEffect(() => {
    console.log(window.localStorage.getItem('accept-terms'))
    //if (!window.localStorage.getItem('accept-terms'))
    mostrarModal()
  }, [idioma])

  function mostrarModal() {
    setShow(true)
    console.log(idioma)
    if (!idioma) setShowPrivacy(true)
  }

  return (
    <>
      <NavbarPage setIdioma={setIdioma} idioma={idioma} />
      <Container>
        <ContenedorImagenTexto idioma={idioma} />
        <ParrafoBoton idioma={idioma} />
        <ImagenParrafo idioma={idioma} />
        <ParrafosImagen idioma={idioma} />
        <FrasesCarrousel idioma={idioma} />
        <TextoLinks idioma={idioma} />
      </Container>
      <Footer idioma={idioma} />

      <ModalTC
        titulo={termsTitle[idioma]}
        show={show}
        setShow={setShow}
        idioma={idioma}
      />

      <ModalPrivacy
        titulo="Aviso de Privacidad"
        show={showPrivacy}
        setShow={setShowPrivacy}
        idioma={idioma}
      />
    </>
  )
}
